var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"rootDiv"}},_vm._l((_vm.fullOptionDB),function(optionType){return _c('div',{key:optionType.OptionType},[_c('v-card',[_c('br'),_c('h1',[_vm._v(" "+_vm._s(optionType.OptionType)+" ")]),(optionType.OptionType != 'Diagnosis')?_c('v-btn',{staticClass:"mb-2",attrs:{"color":"primary","dark":"","depressed":""},on:{"click":function($event){return _vm.createOptionItem(optionType)}}},[_vm._v(" "+_vm._s(_vm.$t("new-option"))+" ")]):_vm._e(),(optionType.Items.length > 0 && optionType.Items[0].id)?_c('table',[_c('tr',_vm._l((_vm.langs),function(lang){return _c('th',{key:lang},[_vm._v(" "+_vm._s(lang)+" ")])}),0),_vm._l((optionType.Items),function(item,index){return _c('tr',{key:item.id},_vm._l((_vm.langs),function(lang){return _c('td',{key:lang},[_c('v-text-field',{attrs:{"value":item[lang]},on:{"change":function($event){return _vm.onChangeDictOption($event, optionType.OptionType, index, lang)}}})],1)}),0)})],2):_vm._e(),(
          optionType.Items.length > 0 &&
            typeof optionType.Items[0] == 'string'
        )?_c('table',_vm._l((optionType.Items),function(item,index){return _c('tr',{key:item},[_c('td',[_c('v-text-field',{ref:optionType.OptionType + '_' + index,refInFor:true,attrs:{"value":optionType.Items[index]},on:{"change":function($event){return _vm.onChangeStringOption($event, optionType.OptionType, index)}}})],1)])}),0):_vm._e(),(optionType.Items.length > 0 && optionType.Items[0].CategoryName)?_c('div',_vm._l((optionType.Items),function(item,index){return _c('div',{key:item.id},[_c('v-btn',{staticClass:"mb-2",attrs:{"color":"primary","dark":"","depressed":""},on:{"click":function($event){return _vm.createDiagnosisItem(item)}}},[_vm._v(" "+_vm._s(_vm.$t("new-option"))+" ")]),_c('table',[_c('tr',_vm._l((_vm.langs),function(lang){return _c('th',{key:lang},[_vm._v(" "+_vm._s(lang)+" ")])}),0),_c('tr',_vm._l((_vm.langs),function(lang){return _c('th',{key:lang},[_c('v-text-field',{attrs:{"multi-line":"","value":item.CategoryName[lang]},on:{"change":function($event){return _vm.onChangeCategoryNameOption(
                      $event,
                      optionType.OptionType,
                      index,
                      lang
                    )}}})],1)}),0),_vm._l((item.CategoryItems),function(citem,index2){return _c('tr',{key:citem.id},_vm._l((_vm.langs),function(lang){return _c('td',{key:lang},[_c('v-text-field',{attrs:{"multi-line":"","value":citem[lang]},on:{"change":function($event){return _vm.onChangeInCategoryOption(
                      $event,
                      optionType.OptionType,
                      index,
                      lang,
                      index2
                    )}}})],1)}),0)})],2),_c('br'),_c('br')],1)}),0):_vm._e()],1)],1)}),0)}
var staticRenderFns = []

export { render, staticRenderFns }