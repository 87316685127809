<template>
  <div id="rootDiv">
    <div v-for="optionType in fullOptionDB" :key="optionType.OptionType">
      <v-card>
        <br />
        <h1>
          {{ optionType.OptionType }}
        </h1>
        <v-btn
          color="primary"
          dark
          class="mb-2"
          @click="createOptionItem(optionType)"
          depressed
          v-if="optionType.OptionType != 'Diagnosis'"
        >
          {{ $t("new-option") }}
        </v-btn>

        <table v-if="optionType.Items.length > 0 && optionType.Items[0].id">
          <tr>
            <th v-for="lang in langs" :key="lang">
              {{ lang }}
            </th>
          </tr>
          <tr v-for="(item, index) in optionType.Items" :key="item.id">
            <td v-for="lang in langs" :key="lang">
              <v-text-field
                :value="item[lang]"
                v-on:change="
                  onChangeDictOption($event, optionType.OptionType, index, lang)
                "
              />
            </td>
          </tr>
        </table>

        <table
          v-if="
            optionType.Items.length > 0 &&
              typeof optionType.Items[0] == 'string'
          "
        >
          <tr v-for="(item, index) in optionType.Items" :key="item">
            <td>
              <v-text-field
                :value="optionType.Items[index]"
                v-on:change="
                  onChangeStringOption($event, optionType.OptionType, index)
                "
                :ref="optionType.OptionType + '_' + index"
              />
            </td>
          </tr>
        </table>

        <div
          v-if="optionType.Items.length > 0 && optionType.Items[0].CategoryName"
        >
          <div v-for="(item, index) in optionType.Items" :key="item.id">
            <v-btn
              color="primary"
              dark
              class="mb-2"
              @click="createDiagnosisItem(item)"
              depressed
            >
              {{ $t("new-option") }}
            </v-btn>

            <table>
              <tr>
                <th v-for="lang in langs" :key="lang">
                  {{ lang }}
                </th>
              </tr>
              <tr>
                <th v-for="lang in langs" :key="lang">
                  <v-text-field
                    multi-line
                    :value="item.CategoryName[lang]"
                    v-on:change="
                      onChangeCategoryNameOption(
                        $event,
                        optionType.OptionType,
                        index,
                        lang
                      )
                    "
                  />
                </th>
              </tr>
              <tr v-for="(citem, index2) in item.CategoryItems" :key="citem.id">
                <td v-for="lang in langs" :key="lang">
                  <v-text-field
                    multi-line
                    :value="citem[lang]"
                    v-on:change="
                      onChangeInCategoryOption(
                        $event,
                        optionType.OptionType,
                        index,
                        lang,
                        index2
                      )
                    "
                  />
                </td>
              </tr>
            </table>

            <br /><br />
          </div>
        </div>
      </v-card>
    </div>
  </div>
</template>

<script>
import { cloudApi } from "../api";

export default {
  name: "OptionEditor",
  data: () => ({
    message: "Hello",
    fullOptionDBData: null
  }),
  mounted() {
    cloudApi
      .get("get-option", { params: { OptionType: "all" } })
      .then(this.setOptionDB);
  },
  watch: {},
  computed: {
    fullOptionDB() {
      if (!this.fullOptionDBData) {
        return [];
      } else {
        return this.fullOptionDBData;
      }
    },
    langs() {
      for (var i = 0; i < this.fullOptionDB.length; i++) {
        if (this.fullOptionDB[i].OptionType == "Language") {
          return this.fullOptionDB[i].Items;
        }
      }

      return ["en", "hu"];
    }
  },
  methods: {
    saveOption(targetOption) {
      cloudApi.post("set-option", targetOption);
    },
    createOptionItem(option) {
      if (option.OptionType == "Language") {
        option.Items.unshift("");
      } else if (
        option.Items.length > 0 &&
        typeof option.Items[0] === "string"
      ) {
        option.Items.unshift("");
      } else {
        var newOption = {};
        newOption["id"] = option.OptionType + "_" + Date.now();
        //newOption["en"] = newOption["id"];
        option.Items.unshift(newOption);
      }
    },
    createDiagnosisItem(item) {
      var newDiagnosis = {};
      newDiagnosis["id"] = "Diagnosis_" + Date.now();
      //newDiagnosis["en"] = newDiagnosis["id"];
      item.CategoryItems.unshift(newDiagnosis);
    },
    onChangeStringOption(newValue, OptionType, index) {
      for (var i = 0; i < this.fullOptionDB.length; i++) {
        if (this.fullOptionDB[i].OptionType == OptionType) {
          var targetOption = this.fullOptionDB[i];
          targetOption.Items[index] = newValue;

          if (newValue === "") {
            targetOption.Items.splice(index, 1);
          }

          this.saveOption(targetOption);

          this.fullOptionDBData[i] = targetOption;

          var orig = this.fullOptionDBData;
          this.fullOptionDBData = [];
          this.fullOptionDBData = orig;

          return;
        }
      }
    },
    onChangeDictOption(newValue, OptionType, index, lang) {
      for (var i = 0; i < this.fullOptionDB.length; i++) {
        if (this.fullOptionDB[i].OptionType == OptionType) {
          var targetOption = this.fullOptionDB[i];
          targetOption.Items[index][lang] = newValue;
          this.fullOptionDBData[i] = targetOption;

          var hasData = false;

          //console.log(targetOption.Items[index]);

          for (var j = 0; j < this.langs.length; j++) {
            var value = targetOption.Items[index][this.langs[j]];

            if (value !== "" && value !== undefined) {
              hasData = true;
            }
          }

          if (!hasData) {
            targetOption.Items.splice(index, 1);
          }
          this.saveOption(targetOption);

          var orig = this.fullOptionDBData;
          this.fullOptionDBData = [];
          this.fullOptionDBData = orig;

          return;
        }
      }
    },
    onChangeCategoryNameOption(newValue, OptionType, index, lang) {
      for (var i = 0; i < this.fullOptionDB.length; i++) {
        if (this.fullOptionDB[i].OptionType == OptionType) {
          var targetOption = this.fullOptionDB[i];
          targetOption.Items[index].CategoryName[lang] = newValue;
          this.fullOptionDBData[i] = targetOption;

          this.saveOption(targetOption);

          var orig = this.fullOptionDBData;
          this.fullOptionDBData = [];
          this.fullOptionDBData = orig;

          return;
        }
      }
    },
    onChangeInCategoryOption(newValue, OptionType, index, lang, index2) {
      for (var i = 0; i < this.fullOptionDB.length; i++) {
        if (this.fullOptionDB[i].OptionType == OptionType) {
          var targetOption = this.fullOptionDB[i];
          targetOption.Items[index].CategoryItems[index2][lang] = newValue;
          this.fullOptionDBData[i] = targetOption;

          var hasData = false;

          for (var j = 0; j < this.langs.length; j++) {
            var value =
              targetOption.Items[index].CategoryItems[index2][this.langs[j]];

            if (value !== "" && value !== undefined) {
              hasData = true;
            }
          }

          if (!hasData) {
            targetOption.Items[index].CategoryItems.splice(index2, 1);
          }

          this.saveOption(targetOption);

          var orig = this.fullOptionDBData;
          this.fullOptionDBData = [];
          this.fullOptionDBData = orig;

          return;
        }
      }
    },
    setOptionDB(response) {
      this.fullOptionDBData = response.data;
    },
    getItemKey(item) {
      if (item.CategoryName) {
        return item.CategoryName.id;
      }
      if (item.id) {
        return item.id;
      }
      return item;
    }
  }
};
</script>

<style lang="scss" scoped>
table {
  width: 100%;
}
table,
th,
td {
  border: 2px solid black;
  border-collapse: collapse;
}
</style>
